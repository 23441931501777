import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import RgpdBanner from 'rgpd-banner';
import ReactGA from 'react-ga';

import 'rgpd-banner/dist/rgpd-banner.css';

/**
 * RgpdDash is a component for display the Rgpd Banner
 */
export default class RgpdDash extends Component {
    componentDidMount() {
        this.initializeRgpdOptions();
    }

    initializeRgpdOptions() {
        const { variant, trackingCode, locale, isDebug } = this.props;
        const rgpd = new RgpdBanner(trackingCode, {
            variant,
            locale,
            container: variant === 'options' ? '#rgpd-options' : 'body',
            consentCallback: trackingCode => {
                if (!isDebug) {
                    ReactGA.initialize(trackingCode, {
                        debug: false,
                    });
                    ReactGA.set({ anonymizeIp: true });
                    ReactGA.pageview(
                        window.location.pathname + window.location.search
                    );
                }
            },
        });
    }

    render() {
        const { variant } = this.props;
        return variant === 'options' ? <div id="rgpd-options" /> : null;
    }
}

RgpdDash.defaultProps = {
    variant: 'banner',
    isDebug: false,
    locale: 'fr',
};

RgpdDash.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * The GA ID
     */
    trackingCode: PropTypes.string.isRequired,

    /**
     * The variant used to display options or banner component
     */
    variant: PropTypes.oneOf(['banner', 'options']),

    /**
     * The locale
     */
    locale: PropTypes.oneOf(['fr', 'en']),

    /**
     * Is debug enabled
     */
    isDebug: PropTypes.bool,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,
};
